.wrapper {
  margin-bottom: 70px;

  .title {
    margin-bottom: 40px;
  }

  .list {
    // border: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li {
      width: 100%;
    }

    li:first-child {
      // margin-bottom: 56px;

      @media (max-width: 768px) {
          margin-bottom: 56px;
      }

    }

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
