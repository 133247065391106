.wrapper {
  border: 1px solid #454545;
  padding: 32px 20px 32px 20px;

  width: 355px;
  border-radius: 16px;

  .textTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .textContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}


.wrapperBlack {
  border: 1px solid #454545;
  padding: 32px 20px 32px 20px;

  width: 355px;
  border-radius: 16px;
  background-color: 
  #454545;

  .textTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: white;
  }

  .textContent {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: white;
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
