/* У файлі QuestionForm.module.scss */

.questionForm {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.formTitle {
  font-size: 24px;
  margin-bottom: 16px;
}

.formDescription {
  font-size: 16px;
  margin-bottom: 12px;
}

.formList {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;

  li {
    margin-bottom: 8px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #aebeaf;
    }
  }

  .submitButton {
    background-color: #4caf50;
    color: white;
    margin-right: 8px;
  }

  .cancelButton {
    background-color: #f44336;
    color: white;
    margin-left: 8px;
  }
}
