.wrapper {
  .wrapperArrow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70px;

    .buttonArrow {
      border: none;
      width: auto;
      height: auto;

      .icon {
        color: rgb(255, 255, 255, 0.5);

        &:hover {
          color: rgb(255, 255, 255);
        }
      }
    }
  }

  .containerSlider {
    // border: 1px solid rgb(255, 71, 71);
    margin-bottom: 60px;
  }
}

.wrapperSlider {
  position: relative;
  // border: 1px solid rgb(84, 28, 203);

  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  width: 100%;

  .list {
    left: 50%;
   

    // border: 1px solid rgb(40, 32, 165);
    display: flex;

    gap: 200px;

    align-items: flex-end;
    position: absolute;
    transition: transform 0.3s ease;

    .item {
      position: relative;
      transform: translateX(-50%);
      display: flex;
      // border: 1px solid rgb(185, 20, 20);

      flex-direction: column;

      flex-shrink: 0;

      .image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }

      .list_1 {
        display: flex;

        gap: 10px;
        .item_1 {
          img {
          }
          p {
            font-size: 10px;
            font-weight: 700;
            line-height: 24px;
            color: #fff;
          }
        }
      }
    }

    @media (max-width: 1360px) {
    }

    @media (max-width: 769px) {
    }
  }

  .pagination {
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination_dots {
      display: flex;
      gap: 16px;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }

      .activeDot {
        background-color: #ffffff;
      }
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 769px) {
    height: 150px;
  }
}

.wrapperCardLi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}
