:root {
  /* --main-color: #03989E; */
  --main-color: #fff;

  --main-color_background: rgba(3, 152, 158, 0.5);
  --text-color-white: #FFFFFF;
  --text-color-black: #000000;
  --text-color-green: #03989E;
  --page-color-white: #FFFFFF;
  --page-color-gray: #F5F5F5;
}

body {

  margin: 0;
  padding: 0;

  font-family: Open Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
}

.greenLine button{
  background-color: rgba(54, 87, 0, 0.4);
}

.all {
  color: var(--4, #292E33);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 350px;
  font-family: "Roboto Condensed";
}

.all span {
  display: block;
  color: var(--4, #292E33);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 5px;
  font-family: "Roboto Condensed";
}


.molasses span {
  font-weight: 300;
}

.compost {
  background-color: #363636;
}

.molasses {
  background-color: #fff;
}

.molasses p ,
.molasses h2,
.molasses h3,
.molasses h4 {
  color: black !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none; 
}

img {
  max-width: 100%;
}

input,
button,
textarea {
  outline: none;
}
