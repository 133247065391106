.wrapperNavLine {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  margin-right: auto;

 .list {
  display: flex;
  align-items: center;

  .item {
    font-weight: 700;
    font-size: 16px;
   line-height: 20px;
   white-space: nowrap;
  }

  .item:not(:last-child) {
    margin-right: 24px;
}
 }



  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

.buttonLink {
  button {
    min-width: 100px;
  }
}

.wrapperNavColumn {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;

 .list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  // align-items: center;

  .item {

    font-size: 20px;
    font-weight: 900;
    line-height: 25px;

    
   white-space: nowrap;
  }

//   .item:not(:last-child) {
//     margin-right: 24px;
// }
 }



  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    align-items: start;
  }
}

@media screen and (max-width: 630px) {  
  .wrapperNavLine {
    .list {
      .item:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
  .buttonLink {
    button {
      min-width: 45px;
    }
  }
}