.mapWrapper {
  position: relative;
  width: 100%;
  height: 0%;
 min-height: 300px;
  padding-bottom: 20%; 
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 0;
    filter: grayscale(100%);
  }
}
