.swiper {
  z-index: -1;
}

.swiper-wrapper {
    max-height: 768px;
}

.slider-main {
    .container {
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        img, video {
            max-width: 110%;
            padding: 100px 50px 100px 200px;
            margin-right: 50px;
            border-radius: 50%;
        }
    }

    h1 {
        color: #FFF;
        text-transform: uppercase;
        font-family: "Rubik Mono One", monospace;
        font-weight: 400;
        font-style: normal;
        font-size: 90px;
        line-height: 90px;
        margin-bottom: 10px;
    }

    h5 {
      color: #FFF;
      font-size: 16px;
      line-height: 24px;
      max-width: 450px;
      font-weight: 400;
    }
}

.main__inner-content {
  max-width: 600px;
  width: 100%;
}

.swiper-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-right: 46px;
}

.swiper-slide {
  display: flex;
}

.swiper-pagination-bullet {
  background: url('../../media/icons/indicator.svg');
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  width: 21px;
  height: 22px;
  background: url('../../media/icons/indicator-active.svg');
}

.swiper-vertical .swiper-button-next,
.swiper-vertical .swiper-button-prev {
//   transform: rotate(90deg);
  left: calc(100% - 33px);
  width: 24px;
  height: 24px;
	transform-origin: left center;
  &::after {
    display: none;
  }
}
.swiper-vertical .swiper-button-prev {
  background-image: url('../../media/icons/arrow-circle-next.svg');
  top: calc(var(--swiper-navigation-size) * 5);
}
.swiper-vertical .swiper-button-next {
  background-image: url('../../media/icons/arrow-circle-next.svg');
  transform: rotate(180deg);
  left: calc(100% - 6px);
  top: auto;
	bottom: calc(var(--swiper-navigation-size) * 5);
}

@media screen and (max-width: 1620px) { 
  .slider-main {
    .container {
      video {
        padding: 100px 50px 100px 50px;
        border-radius: 0 50% 50% 0;
      }
    }
  }
}

@media screen and (max-width: 1500px) { 
  .slider-main {
    .container {
      video {
        max-width: 50%;
        padding: 0px 50px;
        border-radius: 50%;
        margin-right: 0;
      }
    }
    h1 { 
      font-size: 75px;
      line-height: 75px;
    }
  }
  .swiper-wrapper {
    max-height: 668px;
  }
}

@media screen and (max-width: 1240px) { 
  .slider-main {
    h1 { 
      font-size: 60px;
      line-height: 60px;
    }
  }
  .swiper-wrapper{
    max-height: 580px;
  }
  .swiper-vertical .swiper-button-prev {
    top: calc(var(--swiper-navigation-size) * 4);
  }
  .swiper-vertical .swiper-button-next { 
    bottom: calc(var(--swiper-navigation-size) * 3);
  }
}

@media screen and (max-width: 1050px) { 
  .slider-main .container { 
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 100px;

    .main__inner-content {
      order: 2;
    }
    video {
      order: 1;
      padding: 120px 0 0 0;
      max-height: 400px;
      border-radius: none;
    }
  }
  .swiper {
    height: 580px;
  }
  .slider-main h5 {
    max-width: 90%;
  }
}

@media screen and (max-width: 845px) { 
  .swiper {
    height: 680px;
  }
}

@media screen and (max-width: 630px) { 
  .slider-main {
    .container  {
      video { 
        padding: 100px 0 0 0;
        max-width: 80%;
      }
    }

    h1 { 
      font-size: 40px;
      line-height: 40px;
    }
    h5 {
      color: #FFF;
      font-size: 14px;
      line-height: 20px;
    }
  }
}