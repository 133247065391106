.wrapper {
  // border: 1px solid tomato;
  margin-bottom: 100px;

  p, span {
    font-family: "Roboto Condensed";
  }

  .wrapper_Title {
    display: flex;
    justify-content: space-between;

    h4 {
      color: #5d9624;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      max-width: 250px;
    }
  }

  img {
    // position: absolute;
    max-height: 950px;
    object-fit: cover;
    z-index: 1;

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      //   display: none;
    }
  }

  .wrapper_Content {
    // width: 100%;
    // height: 100%;
    // border: 1px solid tomato;
    // margin-bottom: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 20px;

    .block_Organ_0 {
      position: absolute;
      color: #3a3a3a;
      top: 0;
      left: 50%;
      // border: 1px solid #89c244;
      // top: 0;

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      p {
        font-size: 46px;
        font-weight: 700;
        //   line-height: 24px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_124.svg");
        position: absolute;
        top: 70px;
        left: -230px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(-90deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Organ_1 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      top: 0;
      right: 0;
      // border: 1px solid #fa4b05;

      span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 11px;
      }

      p {
        font-size: 46px;
        font-weight: 700;
        line-height: 48px;
        text-transform: uppercase;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_124.svg");
        position: absolute;
        top: 70px;
        left: -230px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(-90deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    //====================================

    .block_Him_0 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      top: 10%;
      left: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_124.svg");
        position: absolute;
        top: 0px;
        left: 350px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(-200deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_1 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      top: 300px;
      left: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_127.svg");
        position: absolute;
        top: -50px;
        left: 300px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(0deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_2 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      top: 500px;
      left: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_124.svg");
        position: absolute;
        top: -70px;
        left: 370px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(-230deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_3 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      bottom: 0;
      left: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
        text-transform: uppercase;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_122.svg");
        position: absolute;
        top: -270px;
        left: 240px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(0deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_4 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      bottom: 0;
      left: 25%;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_121.svg");
        position: absolute;
        top: -170px;
        left: -0px;
        height: 200px;
        width: 200px;
        z-index: -1;
        transform: rotate(0deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_5 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      bottom: 0;
      right: 25%;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_118.svg");
        position: absolute;
        top: -35px;
        left: -150px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(265deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_6 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      bottom: 0;
      right: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_118.svg");
        position: absolute;
        top: -50px;
        left: -150px;
        height: 150px;
        width: 50px;
        z-index: -1;
        transform: rotate(265deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_7 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      bottom: 25%;
      right: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }

      &::before {
        content: url("../../media/icons/gomDecor/Group_118.svg");
        position: absolute;
        top: -50px;
        left: -150px;
        height: 150px;
        width: 50px;
        z-index: -1;
        transform: rotate(265deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .block_Him_8 {
      position: absolute;
      color: #3a3a3a;
      width: 500px;
      top: 40%;
      right: 0;
      // border: 1px solid #fa4b05;
      width: 300px;

      span {
        display: block;
        font-size: 46px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 11px;
        line-height: 48px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
      &::before {
        content: url("../../media/icons/gomDecor/Group_119.svg");
        position: absolute;
        top: -70px;
        left: -230px;
        height: 150px;
        width: 150px;
        z-index: -1;
        transform: rotate(0deg);
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 1360px) {
    display: none;
  }

  @media (max-width: 768px) {
    // display: none;
  }
}

@media (max-width: 1565px) {
  .wrapper {
    img {
      max-height: 800px;
    }
    .wrapper_Content {

      .block_Organ_1 {
        width: 450px;
        &::before {
          top: 130px;
        }
      }
      
      .block_Organ_0, .block_Organ_1 {
        p {
          font-size: 36px;
        }
      }
      .block_Him_0,.block_Him_1,.block_Him_2,.block_Him_3,.block_Him_4,.block_Him_5,.block_Him_6,.block_Him_7,.block_Him_8 {
        width: 250px;
        span {
          font-size: 36px;
        }
      }
    }
  } 
}
