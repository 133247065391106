.wrapper {
  // border: 1px solid black;
  margin-bottom: 90px;

  .title {
    margin-bottom: 50px;
  }

  .list {
    display: flex;
    gap: 30px;
    // width: 217px;
    // height: 104px;

    .item {
      border: 1px solid #454545;
      border-radius: 16px;
      padding: 24px 33px;

      .imgNormal {
        display: block;
      }

      .imgHover {
        display: none;
      }

      &:hover .imgNormal {
        display: none;
      }

      &:hover .imgHover {
        display: block;
      }
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

@media screen and (max-width: 630px) {
  .wrapper {
    .list {
      .item {
        padding: 10px 17px;
      }
    }
  }
}
