.wrapper {
    margin-bottom: 180px;

    h3 {
        color: #171717;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 116px;
        letter-spacing: 4.8px;
        margin-bottom: 48px;
    }
}

.inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
        max-width: 280px;
        object-fit: none;
        max-height: 382px;
    }

    p {
        color: #292E33;
        text-align: center;
        font-family: "Roboto Condensed";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        max-width: 145px;
        margin-top: -20px;
    }
}

@media screen and (max-width: 1442px) { 
    .wrapper {
        margin-bottom: 100px;
        
        h3 {
            line-height: 50px;
        }
    }
}

@media screen and (max-width: 1050px) { 
    .wrapper {
        h3 {
            font-size: 35px;
            line-height: 40px;
        }
    }
 }

 @media screen and (max-width: 630px) { 
    .wrapper {
        h3 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        .block {
            max-width: 124px;
            // overflow: hidden;
            img {
                object-fit: contain;
                max-width: 205px;
            }
            p {
                margin-top: -50px;
                font-size: 14px;
                line-height: 16px
            }
        }
    }
 }