.wrapper {
    // margin-bottom: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px; 

    h4 {
        color: #5D9624;
        font-family: "Open Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        max-width: 250px;
    }

    img {
        max-height: 950px;
        object-fit: cover;
        z-index: 1;
    }
}

.innerTop {
    position: absolute;
    width: 100%;
    top: 0;
}

.innerBottom {
    position: absolute;
    width: 100%;
    bottom: 150px;
}

.title1 {
    position: absolute;
    left: 0;
    top: -80px;
}
.title2 {
    position: absolute;
    left: 0;
    top: -200px;
}

.block0 {
    position: absolute;
    left: 0;
    top: 10px;
    max-width: 300px;
    &::before {
        content: url('../../media/icons/block0.svg');
        position: absolute;
        bottom: -60px;
        left: 80%;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(-15deg);
    }
}

.block1 {
    position: absolute;
    top: 10px;
    transform: translateX(-50%);
    left:50%;
    max-width: 250px;
    &::before {
        content: url('../../media/icons/block1.svg');
        position: absolute;
        bottom: -65px;
        left: 0;
        height: 50px;
        width: 50px;
        z-index: -1;
    }
}

.block2 {
    position: absolute;
    top: 10px;
    right:0;
    max-width: 445px;

    &::before {
        content: url('../../media/icons/block2.svg');
        position: absolute;
        bottom: -40px;
        left: -230px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(10deg);
    }
}

.block3 {
    position: absolute;
    top: 210px;
    left: 0;
    max-width: 250px;
    &::before {
        content: url('../../media/icons/block3.svg');
        position: absolute;
        bottom: 10px;
        right: -40px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(-30deg);
    }
}

.block4 {
    position: absolute;
    top: 250px;
    right:0;
    max-width: 300px;

    &::before {
        content: url('../../media/icons/block4.svg');
        position: absolute;
        top: -50px;
        left: -260px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(20deg);
    }
}

//////////////////////////////// bottom

.block5 {
    position: absolute;
    bottom: 0;
    left:0;
    max-width: 185px;
    span {
        font-size: 46px;
        line-height: 100%;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &::before {
        content: url('../../media/icons/block1.svg');
        position: absolute;
        top: -65px;
        left: 265px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(-75deg);
    }
}
.block6 {
    position: absolute;
    bottom: 0;
    left: 30%;
    max-width: 210px;
    span {
        font-size: 46px;
        line-height: 100%;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &::before {
        content: url('../../media/icons/block6.svg');
        position: absolute;
        top: -170px;
        left: 20px;
        height: 50px;
        width: 50px;
        z-index: -1;
    }
}
.block7 {
    position: absolute;
    bottom: 0;
    left: 60%;
    max-width: 250px;
    span {
        font-size: 46px;
        line-height: 100%;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &::before {
        content: url('../../media/icons/block7.svg');
        position: absolute;
        top: -170px;
        left: -100px;
        height: 50px;
        width: 50px;
        z-index: -1;
    }
}
.block8 {
    position: absolute;
    bottom: 0;
    right:0;
        max-width: 210px;
    span {
        font-size: 46px;
        line-height: 100%;
        font-weight: 700;
        margin-bottom: 5px;
    }
    &::before {
        content: url('../../media/icons/block1.svg');
        position: absolute;
        top: -70px;
        left: -65px;
        height: 50px;
        width: 50px;
        z-index: -1;
        transform: rotate(160deg);
    }
}

@media (max-width: 1442px) {
    .wrapper {
        flex-direction: column;
        min-height: 600px;
        margin-bottom: 100px;
        
        img {
            display: none;
        }
        *::before {
            display: none;
        }
        .block0,.block1,.block2,.block3,.block4,.block5,.block6,.block7,.block8 {
            position: initial;
            transform: initial;
        }
        .block0,.block1,.block2,.block3,.block4 {
            max-width: 50%;
        }

        .block3 {
            max-width: 478px;
        }

        .title1,
        .title2 {
            position: initial;
            min-width: 100%;
            display: block;
        }
    }

    .innerTop {
        margin-bottom: 50px;
    }

    .innerTop,.innerBottom {
        position: initial;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px 0;
    }
}

@media screen and (max-width: 1240px) {
    .wrapper { 
        .block0,.block1,.block2,.block3,.block4 {
            max-width: 100%;
            width: 100%;
        }
    } 
    .innerBottom { 
        gap: 30px 10px;
    } 
}

@media screen and (max-width: 630px) { 
    .wrapper {
        padding: 0;
        .innerTop {
            gap: 16px;
        }

        .block0,.block1,.block2,.block3,.block4 {
            font-size: 14px;
            line-height: 16px;
            max-width: 35%;
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
        .block4 {
            max-width: 98%;
        }
        .block5,.block6,.block7,.block8 {
            font-size: 14px;
            line-height: 16px;
            max-width: 38%;
            span {
                font-size: 24px;
                line-height: 24px;
            }
        }
        .block6 {
            text-align: right;
        }
    }
 }