.homeWrapper {
margin: 0 auto 0 auto;
background-color: tomato  ;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

.head-compost {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 96px;
  opacity: 0.999;
}

.compost-detail {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

@media screen and (max-width: 1050px) {  
  .head-compost {
    background: url('../../media/brown-bg-mobile.svg') !important;
  }
}
