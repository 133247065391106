.wrapper {
// border: 1px solid tomato;
display: flex;
justify-content: space-between;
border-radius: 14px;
overflow: hidden;
background-color: #363636;
padding-top: 30px;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 70px;

.logo {
  margin-bottom: 32px;
}
.wrapperText { 
  display: flex;
  gap: 15px;
  
  margin-bottom: 32px;
}

.wrapperText {
  margin-bottom: 32px;
}

.textTitle {
  margin-bottom: 32px;
  color: #636363;
  margin-top: 30px;
}


.button {
  width: 96px;
height: 64px;
background-color: transparent;

border-radius: 16px;
border: 1px solid #636363;
border-radius: 16px;
color: var(--main-color);

}

.wrapperDecor {
  height: 80px;
}

 

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
