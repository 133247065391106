.buttonStyle {
  border: 1px solid #fff;
  width: 100%;
  height: 96px;
  border-radius: 16px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
  white-space: wrap;
  max-width: 250px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  // display: flex;

  @media (max-width: 1360px) {
    // Додай стилі для екранів ширше 768px тут
  }

  @media (max-width: 768px) {
    // Додай стилі для екранів ширше 1360px тут
  }
}

@media screen and (max-width: 630px) {
  .buttonStyle {
    // width: 50px;
    height: 50px;
    font-size: 9px;
    border-radius: 8px;
    padding: 4px;
  }
}

.activeButton {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -10%;
    left: 50%;
    width: 50%;
    height: 10%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.longButton {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;

  @media (max-width: 1360px) {
    // Додай стилі для екранів ширше 768px тут
  }

  @media (max-width: 768px) {
    // Додай стилі для екранів ширше 1360px тут
    display: none;
  }
}

.iconButtons {
  display: flex;
  align-items: center;
}
