.wrapper {
  margin-bottom: 80px;

  .title {
    align-items: center;
    text-align: center;
    width: 200px;
    height: 40px;
    background-color: #363636;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
  }



  .content {

    border-radius: 16px;
    border: 1px solid #363636;
    border-radius: 16px;
    padding: 50px 110px 50px 110px;

    .wrapperPacking {
      display: flex;
      justify-content: space-between;
      // flex-wrap: wrap;
      // justify-content: center;
      align-items: flex-start;

      .wrapperContent {
        // border: solid 1px tomato;
        // width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        h3 {
          margin-bottom: 44px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: white;
          text-align: center;
        }

        .list_1 {
          display: flex;
          align-items: flex-end;
          gap: 40px;

          .item_1 {
            text-align: center;

            img {
              margin-bottom: 15px;
            }

            p {
              font-size: 24px;
              font-weight: 700;
              line-height: 24px;
              color: white;
            }
          }
        }

        .list_2 {
          display: flex;
          // align-items: end;
          flex-direction: column;
          gap: 40px;

          .item_2 {
            text-align: center;

            img {
              margin-bottom: 15px;
            }

            p {
              font-size: 24px;
              font-weight: 700;
              line-height: 24px;
              color: white;
            }
          }
        }
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .wrapper__description {
      
      h3 {
        // border: 1px solid tomato;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .flexContainer {
        display: flex;
        gap: 10px;

        .description_container {
          margin-bottom: 15px;

          .description_boxTitle {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;

            h4 {
              color: #fff;

              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
            }
          }

          .description_decorWrapper {
            // border: 1px solid rgb(26, 139, 52);
            padding: 0 0 0 30px;
            color: #fff;

            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }
        @media (max-width: 768px) {
          flex-wrap: wrap;
          // margin-left: auto;
          // margin-right: auto;
        }
      }
    }

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      padding: 10px;
    }
  }





}
