.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid tomato;

  padding: 25px 0 25px 0;

  position: absolute;
  width: 100%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;

  .wrapperLogo {
    margin-right: 50px;
  }

  .wrapperButton {
    display: flex;
    gap: 18px;
    
    @media (max-width: 568px) {
      gap: 5px;
    }
  }

  .wrapperShow {
    display: flex;
    align-items: center;
    width: 100%;
    // justify-content: space-between;

    .wrapperNavLine {
      width: 100%;
      // margin-right: auto;
      // border: 1px solid tomato;

      @media (max-width: 1360px) {
        display: none;
      }

      @media (max-width: 768px) {
      }
    }

    .nawDropdown {
      // border: 1px solid tomato;
      margin-right: auto;
      display: none;

      @media (max-width: 1360px) {
        display: block;
      }

      @media (max-width: 768px) {
        // display: none;
      }
    }

    .wrapperDecorContacts {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      margin-right: 40px;

      .decorLine {
        margin: 0 12px 0 12px;
        color: var(--text-color-white);
      }

      @media (max-width: 768px) {
        // display: none;
      }
    }

    .wrapperSwitcher {
      // height: 100%;

      @media (max-width: 768px) {
        // display: none;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .burgerButton {
    display: none;
    margin-left: auto;
    border: none;
    background: transparent;
    color: var(--text-color-white);

    .openIcon,
    .closeIcon {
      width: 30px;
      height: 30px;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media screen and (max-width: 630px) {
    .wrapperLogo {
      margin: 0 auto;
    }
  }

  @media (max-width: 1360px) {
    padding: 20px 0 20px 0;
  }

  @media (max-width: 768px) {
  }
}

.burgerContent {
  // position: absolute;
  background-color: var(--main-color);
  width: 100vh;
  // height: 100vw;

  z-index: 20;
  // top: 90px;
  padding-top: 60px;
  padding-bottom: 60px;

  .wrapperNavBurger {
    margin-bottom: 80px;
  }

  .wrapperDecorContactsBurger {
    margin-bottom: 60px;
  }
}
