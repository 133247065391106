.container {
// border: 1px solid tomato;
  margin-left: auto;
  margin-right: auto;
  padding: 0 120px 0 120px;
  max-width: 1920px;
  // background-color: var(--primary-color);
  position: relative;

  @media (max-width: 1920px) {

  }

  @media (max-width: 1040px) {
    padding: 0 24px 0 24px;
  }

  @media (max-width: 767px) {
    padding: 0 16px 0 16px;
  }


}
