.titleText {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;

  
  color: var(--text-color-black);


  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {

  }
}

.titleTextColor {
  font-size: 32px;
font-weight: 700;
line-height: 32px;

  color: var(--text-color-white);


  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {

  }
}

@media screen and (max-width: 630px) { 
  .titleText{
    font-size: 24px;
    line-height: 32px;
  }
}
