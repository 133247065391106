.wrapper {
  // border: 1px solid black;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  margin-bottom: 70px;

  .wrapperTitle {
    .title {
      margin-bottom: 30px;
    }

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }

    .wrapperDescription {
      display: flex;
      gap: 40px;


      @media (max-width: 1360px) {
      }
    
      @media (max-width: 768px) {
   flex-direction: column;
      }
    }



    @media (max-width: 1360px) {
    }
  
    @media (max-width: 768px) {
 margin-bottom: 100px;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}


.wrapperTitle {

  h3 {
    text-align: center;
    margin-bottom: 30px;
  }
}