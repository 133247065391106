
.wrapper {
  margin-bottom: 80px;
  


  .title {
    align-items: center;
    text-align: center;
    width: 200px;
    height: 40px;
    background-color: #363636;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
  }
  
  
  .text {
    text-align: center;
    border: 1px solid #363636;
    padding: 25px 190px;
    border-radius: 16px;

    font-size: 18px;
    font-weight: 400;
    line-height: 24px;


    .decor {


      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
  


    }
  }
  
  @media (max-width: 1360px) {
  }
  
  @media (max-width: 768px) {
  }
}

@media screen and (max-width: 1050px) {
  .wrapper {
    .text {
      padding: 25px 50px;
    }
  } 
}


@media screen and (max-width: 630px) { 
  .wrapper {
    .text {
      padding: 23px 10px;
      font-size: 14px;
    }
  } 
}