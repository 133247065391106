.wrapper {
  margin-bottom: 80px;

  .title {
    align-items: center;
    text-align: center;
    width: 200px;
    height: 40px;
    background-color: #363636;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
  }

  .content {
    background-color: #363636;
    text-align: center;
    border: 1px solid #363636;
    padding: 25px 0px;
    border-radius: 16px;
    overflow: hidden;

    .decorBox {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-bottom: 30px;
      

      .boxHidden {
        // border: 1px solid tomato;
        border-radius: 16px;
        overflow: hidden;
      }

      .decor_left {
        position: absolute;
        left: -15%;
        height: 100%;
      
      }

      .decor_right {
        position: absolute;
        right: -15%;
        height: 100%;
      }
    }




    .text {
      color: var(--main-color);
      width: 500px;
margin-left: auto;
margin-right: auto;


font-size: 16px;
font-weight: 400;
line-height: 24px;



    }

  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    display: none;
  }
}
