.contactsWrapper {
  padding-top: 54px;
  padding-bottom: 104px;
  border-bottom: 1px solid #292e33;
}

.contactsWrapper_title {
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  margin-bottom: 16px;
  width: 25%;
  padding-bottom: 24px;
  align-items: center;
  h2 {
    display: block;
    color: #c2c7cc;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.slider {
  position: relative;

  .prevArrow,
  .nextArrow {
    position: absolute;
    fill: rgba(69, 73, 77, 1);
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: fill 0.3s ease, color 0.3s ease;

    &:hover {
      fill: rgba(194, 199, 204, 1);
    }

    @media (max-width: 768px) {
      display: none;
    }

  }

  .prevArrow {
    left: -80px;
  }

  .nextArrow {
    right: -80px;
  }
}

.slidesContainer {
  overflow: hidden;
  width: 100%;

  .wrapperContPag {
    display: flex;
    flex-direction: column;

    .slidesInnerContainer {
      display: flex;
      align-items: center;
      height: auto;
      transition: transform 0.6s ease-in-out;
      margin-bottom: 32px;

      .slide {
        flex: 0 0 calc(100% / 5);
        transition: opacity 0.3s ease-in-out;
        padding: 0 10px;

        a:hover {
          img {
            filter: grayscale(0);
          }
        }

        img {
          max-height: 120px;
          filter: grayscale(100%);
          transition: all 0.3s;
        }


        @media (max-width: 768px) {
          // flex: 0 0 calc(100% / 5);
        }
      }
    }
  }
}

.pagination {
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 154px;
  overflow: hidden;

  // border: 1px solid gray;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    // background: rgb(34, 36, 38);
    // background: linear-gradient(
    //   90deg,
    //   rgba(34, 36, 38, 1) 0%,
    //   rgba(255, 255, 255, 0) 50%,
    //   rgba(34, 36, 38, 1) 100%
    // );
    z-index: 1;
  }

  .pagination_border {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
  }

  .pagination_dots {
    position: absolute;
    top: 0;

    left: 45.2%;
    display: flex;
    gap: 6px;

    height: 100%;
    align-items: center;
    overflow: hidden;

    transition: transform 0.9s ease;

    .activeDot {
      display: flex;
      align-items: center;
    }
  }
}
