.wrapperLanguage {

  display: flex;
  gap: 8px;


  .langButton {
    position: relative;
    border: 1px solid #fff;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    color: #fff;
    background-color: transparent;
  cursor: pointer;
  
  

  font-size: 12px;
  font-weight: 400;
  line-height: 24px;

  
  
  
  




  }



    .activeLang:before {
      position: absolute;
      content: ""; 
      display: block;
      width: 10px; 
      height: 5px; 
      border-radius: 
      0px 0px 2px 2px;
bottom: -5px;
left: 35%;
      background-color: rgb(255, 255, 255); 
     
    }
    






  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

@media screen and (max-width: 630px) { 
  .wrapperLanguage {
    .langButton {
      width: 24px;
      height: 24px;
      font-size: 8px;
      &:before { 
        left: 28%;
      }
    }
  }
}