.wrapperImg {
  padding: 215px 0 48px 0;
  margin-bottom: 80px;

  // background-color: tomato;
  background-image: url("../../../public/media/images/mainTitle/background_1.jpg");
  background-size: cover;
  // margin-bottom: 70px;

  .wrapper {
    // background-color: tomato;

    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;

    border: 1px solid #fff;
    margin-bottom: 48px;
    // height: 550px;
    border-radius: 16px;
    // padding: 100px 100px 0 100px;

    .wrapperText {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 550px;
      padding: 0 10px 0 10px;

      .textStyle {
        font-size: 36px;
        font-weight: 800;
        line-height: 48px;
        color: var(--main-color);
        text-align: center;
        margin-bottom: 15px;
        text-transform: uppercase;
        // padding: 10px;

        @media (max-width: 1360px) {
        }

        @media (max-width: 768px) {
          display: inline;
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          background-color: #fff;
          color: #3a5600;
          padding: 5px;
          border-radius: 5px;
          margin-bottom: 20px;
        }
      }

      .buttonStyle {
        width: 216px;
        height: 49px;
        background-color: var(--main-color);
        border: none;
        border-radius: 8px 8px 0px 0px;
        color: #163100;

        font-size: 16px;
        font-weight: 600;
        line-height: 36px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgb(255, 255, 255, 0.8);
        }
      }

      @media (max-width: 1360px) {
      }

      @media (max-width: 768px) {
        height: 300px;
      }
    }
    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      // padding: 0px 8px 0 8px;
      // height: 300px;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    padding: 90px 0 33px 0;
    margin-bottom: 30px;
  }
}
