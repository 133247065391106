.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  transition: 0.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.modal__content {
  // padding: 20px;
  border-radius: 15px;
  background-color: #fff;

  width : 70vw;
  // width : 100%;

  // height: 200px;
  // width: 200px;

  transform: scale(0.5);
  transition: 0.4s all;

  &.active {
    transform: scale(1);
  }
}
