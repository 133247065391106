.wrapper {
  // border: 1px solid black;
  margin-bottom: 50px;

  .title {
    margin-bottom: 30px;
  }

  .wrapperButton {
    // background-color: #5d9624;
    // border: 1px solid black;
    display: flex;
    justify-content: center;
    padding: 0 20px 0 20px;


    button {
      border: 1px solid #5d9624;
      color: #5d9624;
      height: 40px;
    }

    .toggleButton_left {
      border-radius: 8px 0 0 0;
    }

    .toggleButton_right {
      border-radius:  0 8px 0 0;
    }

    .activeToggle {
      background-color: #5d9624;
      color: white;
    }
  }

  .decorBox {
    padding: 20px;

    .wrapperSlider {
      // background-color: tomato;
      display: none;

      @media (max-width: 1360px) {
      }

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  .wrapperContent {
    // height: 450px;
    background-color: #5d9624;
    padding: 6px;

    border-radius: 16px;

    .list {
      // border: 1px solid black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      align-items: flex-end;
      height: 100%;

      .item {
        display: flex;
        flex-direction: column;
        height: 120px;
        justify-content: space-between;
        // border: 1px solid tomato;






        .list_1 {
          display: flex;
          justify-content: center;
          gap: 10px;
          .item_1 {
            p {
              font-size: 10px;
              font-weight: 700;
              line-height: 24px;
              color: #fff;
            }

            img {
            }
          }
        }
      }

      @media (max-width: 1360px) {
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .wrapperMaps {
      position: relative;
      // border: 1px solid tomato;
      width: 100%;
      height: auto;
      border-radius: 14px;
      overflow: hidden;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
