.wrapper {
  // border: 1px solid tomato;
  display: none;


  .wrapperList_1 {
    margin-bottom: 70px;
    .title {
      margin-bottom: 40px;
      display: block;

      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
   
      color: #5d9624;

      .titleDecor {
        display: block;

        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        color: #181818;
      }
    }

    .list {
      // border: 1px solid tomato;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .item {
        width: 50%;

        .textTitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        .textDescription {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
  }





  .wrapperList_2 {
    margin-bottom: 70px;
    .title {
      margin-bottom: 40px;
      display: block;

      font-size: 24px;
      font-weight: 700;
      line-height: 24px;

      color: #5d9624;

      .titleDecor {
        display: block;

        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        color: #181818;
      }
    }

    .list {
      // border: 1px solid tomato;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
      .item {
        width: calc(50% - 5px);

        .textTitle {
        

          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .textDescription {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  @media (max-width: 1360px) {
    display: block;
  }

  @media (max-width: 768px) {
    // display: block;
  }
}
