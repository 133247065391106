.homeWrapper {
margin: 0 auto 0 auto;
background-color: tomato  ;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

.head-melasa {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 96px;
  opacity: 0.999;
}

@media screen and (max-width: 1050px) {  
  .head-melasa {
    background: url('../../media/orange-bg-mobile.svg') !important;
  }
}

@media screen and (max-width: 630px) { 
  .head-melasa {
    margin-bottom: 30px;
  }
}
