.wrapper {
  // border: 1px solid black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .wrapperImg {
    border: 1px solid rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 540px;
    height: 300px;
    border-radius: 16px;
    border-color: #454545;

    .img {
      // position: absolute;
      // top: -45px;

      position: relative; 
      top: 0; 
      transition: top 0.3s ease, transform 0.3s ease; 


    }

    &:hover {
      .img {
        transform: scale(1.2);
        top: -45px; 
      }
    }
    .text {
      font-size: 32px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
      margin-top: auto;
    }

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .buttonCard {
    width: 216px;
    height: 40px;

    width: 200px;
    height: 40px;

    border-radius: 0px 0px 8px 8px;
    background-color: #454545;
    color: var(--main-color);

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
