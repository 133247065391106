.wrapper {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-right: 13px;
  }

  .text {
    font-weight: 900;
    white-space: nowrap;
  }
  


  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}

@media screen and (max-width: 630px) { 
  .wrapper {
    margin: 0 auto;
    svg {
      width: 100px;
      height: 50px;
    }
    .icon {
      margin-right: 0;
    }
  }
}
