.wrapper {
  padding: 0 90px 30px 90px;
  // border: 1px solid tomato;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;

  .thankBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0 56px 0;
    margin-top: 35px;
    border-radius: 8px;

    background-color: #5d9624;

    img {
      height: 240px;
      margin-bottom: 30px;
    }

    p {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
    }
  }

  .titleForm {
    background-color: #5d9624;
    width: 200px;
    color: white;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    border-radius: 0 0 8px 8px;
    margin-bottom: 5px;
  }

  .termsCooperation__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .termsCooperation__list {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    margin-bottom: 20px;

    .termsCooperation__item {
      label {
        position: relative;
        display: inline-block;
        cursor: pointer;
        color: #9d9d9d;

        input[type="radio"] {
          display: none;
        }

        .termsCooperation__text_1 {
          padding-left: 30px;
          margin-bottom: 10px;
        }

        .termsCooperation__text_1::before {
          content: "";
          background-image: url(./../../../public/media/icons/form/notSelected.png);
          background-repeat: no-repeat;
          background-size: cover;

          width: 15px;
          height: 15px;
          // border: 3px solid #706b6b;
          position: absolute;
          border-radius: 50%;
          top: 25%;
          left: 0;
          transform: translateY(-50%);
          margin-right: 5px;
        }

        .termsCooperation__text_2 {
          padding-left: 60px;
        }

        .termsCooperation__text_2::before {
          content: "";
          background-image: url(./../../../public/media/icons/form/notSelectedWarning.png);
          background-repeat: no-repeat;

          width: 15px;
          height: 15px;
          // border: 3px solid #878787;
          position: absolute;
          border-radius: 50%;
          bottom: -10%;
          left: 8%;
          transform: translateY(-50%);
          margin-right: 5px;
        }
      }

      label.selected {
        color: #2e2e2e;

        .termsCooperation__text_1::before {
          content: "";
          background-image: url(./../../../public/media/icons/form/selected.png);
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
          // border: 3px solid #37b84d;
          // background-color: #37b84d;
          position: absolute;
          border-radius: 50%;
          top: 25%;
          left: 0;
          transform: translateY(-50%);
          margin-right: 5px;
        }

        .termsCooperation__text_2::before {
          content: "";
          background-image: url(./../../../public/media/icons/form/selectedWarning.png);
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
          // border: 3px solid #ee360d;
          position: absolute;
          border-radius: 50%;
          bottom: -10%;
          left: 8%;
          transform: translateY(-50%);
          margin-right: 5px;
        }
      }
    }
  }

  .nameCompany {
    width: 100%;
    margin-bottom: 30px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    padding: 10px;
  }

  .nameCompany::placeholder {
    color: #878787;
    padding-left: 5px;
  }

  .wrapper__infoProduct {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 30px;

    .product__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      color: #878787;
    }

    .product__list {
      display: flex;
      flex-wrap: wrap;

      gap: 16px;

      .product__item {
        label {
          position: relative;
          display: inline-block;
          cursor: pointer;
          color: #9d9d9d;

          input[type="radio"] {
            display: none;
          }

          .boxImg {
            height: 60px;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 12px;
            padding-left: 12px;

            img {
              width: 40px;
            }
          }
          p {
            text-align: center;
          }
        }

        label.selected {
          color: #2e2e2e;
          .boxImg {
            background-color: #dcdcdc;
          }
        }
      }

      .product__item {
        label {
          position: relative;
          display: inline-block;
          cursor: pointer;
          color: #9d9d9d;

          input[type="radio"] {
            display: none;
          }

          .boxImg {
            height: 60px;
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 12px;
            padding-left: 12px;

            img {
              width: 40px;
            }
          }
          p {
            text-align: center;
          }
        }

        label.selected {
          color: #2e2e2e;
          .boxImg {
            background-color: #dcdcdc;
          }
        }
      }
    }

    .weight {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 5px;
      color: #9d9d9d;

      font-size: 14px;
      font-weight: 400;
      line-height: 32px;

      input {
        height: 60px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;

        align-items: center;

        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }

  .wrapper__description {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 30px;

    .description {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      cursor: pointer;
      color: #000000;

      font-size: 16px;
      font-weight: 700;
      line-height: 32px;

      input {
        height: 40px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;

        align-items: center;

        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }

  // .wrapperButton {
  //   display: flex;
  //   gap: 20px;

  .button__submit {
    background-color: #5d9624;
    height: 40px;
  }

  //   .button__cancel {
  //     background-color: gray;
  //     height: 40px;
  //   }
  // }

  .button__cancel {
    // height: 40px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    width: auto;
    height: auto;
    padding: 0;
    // background-color: gray;
    // border: 1px solid tomato;
    color: tomato;

    .button__icon {
      width: 30px;
      height: 30px;
    }
  }
}
