button {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 64px;
  border: 1px solid var(--main-color);
  border-radius: 8px;
  color: var(--main-color);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  // min-width: 100px;
}

.activeButton {
  border: 1px solid rgba(255, 255, 255);

  .text_1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    // color: #5d9624;
  }

  .text_2 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 110px;
    height: 9px;
    border-radius: 0px 0px 4px 4px;
    bottom: -10px;
    left: 18%;
    background-color: rgb(255, 255, 255);
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
