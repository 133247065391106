.wrapper {
margin-bottom: 70px;


  .titleUsWrapper {
    margin-bottom: 30px;

    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    // border: 1px solid rgb(77, 46, 179);

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
    }
  }

  .contentWrapper {
    // border: 1px solid tomato;
    background-color: #5d9624;
    display: flex;
    padding: 32px 50px 32px 50px;
    gap: 30px;
    justify-content: space-between;
    border-radius: 16px;
    align-items: center;

    .text {
      width: 50%;

      .wrapperTitleContent {
        margin-bottom: 30px;
      }

      .description_1 {
        margin-bottom: 30px;

        a {
          text-decoration: underline;
        }
      }

      .description_1,
      .description_2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--main-color);
      }

      @media (max-width: 1360px) {
      }
  
      @media (max-width: 768px) {
    //  padding-top: 32px;
        width: 100%;
      }
    }

    @media (max-width: 1360px) {
    }

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 16px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
