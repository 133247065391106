.wrapper {
  // border: 1px solid tomato;
  display: flex;
  justify-content: space-between;
  border-radius: 14px;
  overflow: hidden;
  background-color: #5d9624;
  margin-bottom: 70px;
  padding: 30px 0 30px 0;

  .wrapperDecor_1 {


    @media (max-width: 1360px) {
    }
  
    @media (max-width: 768px) {
 display: none;
    }


  }

  .wrapperText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 30px;
    color: var(--main-color);

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .number {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      padding: 10px 120px 10px 120px;
      border: 1px solid var(--main-color);
      border-radius: 16px;
    }

    .name {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      padding: 7px 30px 7px 30px;
      background-color: var(--main-color);
      border-radius: 0px 0px 12px 12px;
      color: #5d9624;
    }

    @media (max-width: 1360px) {
    }
  
    @media (max-width: 768px) {
margin-bottom: 30px;
    }
  }

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1050px) {
  .wrapper {
    .wrapperText {
      .number {
        padding: 10px;
        min-width: 50%;
        width: 100%;
        text-align: center;
      }
    }
  }
}
