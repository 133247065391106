.homeWrapper {
margin: 0 auto 0 auto;
background-color: tomato  ;

  @media (max-width: 1360px) {
  }

  @media (max-width: 768px) {
  }
}
